
.card-wizard .moving-tab {
    position: absolute;
    text-align: center;
    padding: 12px;
    font-size: 12px;
    text-transform: uppercase;
    -webkit-font-smoothing: subpixel-antialiased;
    top: 0px;
    left: 0px;
    background-color: #66615b;
    box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
    color: #FFFFFF !important;
    cursor: pointer;
    font-weight: 500;
}